<template>
  <div class="login-wrap">
    <div class="login_header flex">
        <el-image :src="src"></el-image>
        <el-link style="font-size:16px" type="success" href="https://www.looin.cn/QjaGeuOTbZ.php" target="_blank">登录路印后台 ></el-link>
    </div>
    <div class="ms-login">
      <div class="ms-title">医护端登录</div>
      <el-form
        :model="param"
        :rules="rules"
        ref="login"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="username">
          <el-input v-model="param.username" placeholder="账号">
            <template #prepend>
              <el-button icon="el-icon-user"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="密码"
            v-model="param.password"
            @keyup.enter="submitForm()"
          >
            <template #prepend>
              <el-button icon="el-icon-lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="submitForm()">登录</el-button>
        </div>
        <p class="login-tips">提示 : 如忘记登录密码请联系路印后台管理员</p>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login } from "../api/index";
// import qs from 'qs';
export default {
  name: "login",
  data() {
    return {
      src: require('../assets/img/logo.png'),
      param: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    // let token = localStorage.getItem("token");
    // if(token){
    //   this.$router.push({path: '/dashboard'})
    // }
  },
  methods: {
    submitForm() {
      this.$refs.login.validate((valid) => {
        if (valid) {
          // console.log(qs.stringify(this.param))
          login(this.param).then(res => {
              this.$message.success("登录成功");
              localStorage.setItem("token", res.token);   
              localStorage.setItem("types", res.type);     //专家，护士
              this.$router.push("/");
          });
        } else {
          this.$message.error("请输入账号和密码");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login-bg.png);
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 70px;
  text-align: center;
  font-size: 20px;
  color: #666;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 70%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.4);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #666;
}

/* header */
.login_header{
  width: 100%;
  height: 80px;
  padding: 0 240px;
  padding-right: 400px;
  background-color: rgba(255, 255, 255, 0.7);
}

</style>